<template>
    <div id="tutorial">
        <div class="tutorial-title">
            <h1>DeepCancerMap Tutorial</h1>
        </div>
        <main>
            <div class="content-wrapper">
                <div class="content">
                    <h2 class="content-title">Content</h2>
                    <div class="content-chapter">
                        <a href="javascript:void(0)" @click="goAnchor('title-1')" class="content-title">1. How to use
                            the
                            "Activity Predicting"</a>
                        <div class="content-subtitle">
                            <a href="javascript:void(0)" @click="goAnchor('title-1-1')">1.1 Single molecule anti-cancer
                                activity
                                prediction</a>
                            <a href="javascript:void(0)" @click="goAnchor('title-1-2')">1.2 Submitting batch molecules
                                for
                                prediction</a>
                            <a href="javascript:void(0)" @click="goAnchor('title-1-3')">1.3 Browsing the activity
                                predicting
                                result</a>
                        </div>
                    </div>
                    <div class="content-chapter">
                        <a href="javascript:void(0)" @click="goAnchor('title-2')" class="content-title">2. How to use
                            the
                            "Virtual Screening"</a>
                        <div class="content-subtitle">
                            <a href="javascript:void(0)" @click="goAnchor('title-2-1')">2.1 Submit Virtual Screening
                                mission</a>
                            <a href="javascript:void(0)" @click="goAnchor('title-2-2')">2.2 Browse VS result online</a>
                        </div>
                    </div>
                    <div class="content-chapter">
                        <a href="javascript:void(0)" @click="goAnchor('title-3')" class="content-title">3. How to use
                            the "Similarity Search"</a>
                        <div class="content-subtitle">
                            <a href="javascript:void(0)" @click="goAnchor('title-3-1')">
                                3.1 Submit molecule for similarity search among DeepCancerMap database
                            </a>
                            <a href="javascript:void(0)" @click="goAnchor('title-3-2')">3.2 Browse the similarity
                                search result online</a>
                        </div>
                    </div>
                    <div class="content-chapter">
                        <a href="javascript:void(0)" @click="goAnchor('title-4')" class="content-title">
                            4. How to query and browse result of mission submitted
                        </a>
                        <div class="content-subtitle">
                            <a href="javascript:void(0)" @click="goAnchor('title-4-1')">4.1 Query result by mission
                                ID</a>
                            <a href="javascript:void(0)" @click="goAnchor('title-4-2')">4.2 Browse result by email</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chapter-wrapper">
                <div class="chapter">
                    <h1 class="title" id="title-1">1. How to use the "Activity Predicting"</h1>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-1-1">1.1 Single molecule anti-cancer activity prediction</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Click in navigation bar to go to submitting page.</li>
                            <li class="step-item">Step2: Provide the molecule by SMILES format or draw with JSME Editor.
                            </li>
                            <li class="step-item">Step3: Choose the interested category for prediction.</li>
                            <li class="step-item">Step4: Click the submit button.</li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_1_1.jpg" alt="">
                            </div>
                            <li class="step-item">Step5: Define a custom task name for this submission, and type in the
                                email address for receiving reminder mail with predicting result, finally clicking the
                                Submit button.
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_1_2.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-1-2">1.2 Submitting batch molecules for prediction</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Click in navigation bar to go to submitting page.</li>
                            <li class="step-item">Step2: Click “Batch Predict” button to go to batch submitting page
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_2_1.jpg" alt="">
                            </div>
                            <li class="step-item">Step3: Provide molecules in SMILES format separated with space or
                                carriage-return, or provide by uploading file according to the layout in Example file.
                            </li>
                            <li class="step-item">Step4: Choose the interested category for prediction. </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_2_2.jpg" alt="">
                            </div>
                            <li class="step-item">Step5: Click submit button, then define a custom task name for this
                                submission and type in the email address for receiving reminder mail with predicting
                                result, finally clicking the submit button again.
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_2_3.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-1-3">1.3 Browsing the activity predicting result online</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Enter the result page from ID Query or Links in Email</li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_3_1.jpg" alt="">
                            </div>
                            <li class="step-item">Step2: Predicting result was performed in both the form of simulating
                                pore plate in bio-experiment and the tabular form below. Hovering the mouse pointer over
                                the hole could check the name of cell line or target and the accurate score, while the
                                darker the hole displays the lower prediction score is.
                            </li>
                            <li class="step-item">Step3: Provide molecules in SMILES format separated with space or
                                carriage-return, or provide by uploading file according to the layout in Example file.
                            </li>
                            <li class="step-item">Step4: Choosing the subcategory beside the pore plate would limit the
                                corresponding pores for display.</li>
                            <li class="step-item">Step5: Searching and sorting function were accessible in the detailed
                                table below.
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F1_3_2.jpg" alt="">
                            </div>
                            <li class="step-item">Step6: Clicking the Detail button in each row to check the information
                                of deep-learning model of the cell line or target.</li>
                            <li class="step-item">
                                Step7: Download the result file in CSV format by clicking download button at the bottom
                                or in reminder email directly for local edit.
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="chapter">
                    <h1 class="title" id="title-2">2. How to use the "Virtual Screening"</h1>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-2-1">2.1 Submit Virtual Screening mission</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Click in navigation bar to go to submitting page.</li>
                            <li class="step-item"> Step2: Select a deep-learning model of cancer cell lines or
                                anti-cancer targets. Associative-word search and select by tabulation below are both
                                supported. </li>
                            <li class="step-item">Step3: Upload your molecular dataset according to the format of
                                example file.
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F2_1.jpg" alt="">
                            </div>
                            <li class="step-item">Step4: Click submit button, then define a custom task name for this
                                submission and type in the email address for receiving reminder mail with screening
                                result, finally clicking the submit button again.</li>

                        </ul>
                    </div>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-2-2">2.2 Browse VS result online</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Enter the result page from ID Query or Links in Email.</li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F2_2_1.jpg" alt="">
                            </div>
                            <li class="step-item">Step2: The performance information of DL model using in VS will be
                                shown above.</li>
                            <li class="step-item">Step3: The detail virtual screening result was shown below, however we
                                recommend browsing the result file provided in reminder email for better experience.
                            </li>

                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F2_2_2.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="chapter">
                    <h1 class="title" id="title-3">3. How to use the "Similarity Search"</h1>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-3-1">3.1 Submit molecule for similarity search among DeepCancerMap
                            database.</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Click in navigation bar to go to submitting page.</li>

                            <li class="step-item">Step2: Select the interested classification among Cancer Cell Lines,
                                Anti-cancer Target and NCI-60 Cell Lines
                            </li>
                            <li class="step-item">Step3: Select the fingerprint used for similarity calculation between
                                molecules.
                            </li>
                            <li class="step-item">Step4: Adjust the similarity score range in need, while from zero to
                                one was set as default.</li>

                            <li class="step-item">Step5: Provide the molecule by SMILES format or draw with JSME Editor.
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F3_1.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-3-2">3.2 Browse the similarity search result online.</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Enter the result page from ID Query or Links in Email.</li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F3_2_1.jpg" alt="">
                            </div>
                            <li class="step-item">Step2: The basic information of submitted by user will be shown in the
                                table above.
                            </li>
                            <li class="step-item">Step3: The detail similarity search result would be shown below,
                                however we recommend to browse the result file provided in reminder email for better
                                experience.
                            </li>
                            <li class="step-item">Step4: Click the Detail button in each row to observe the cancer cell
                                lines or anti-cancer target that effected by this molecule in DEEPCANCERMAP database.</li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F3_2_2.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="chapter">
                    <h1 class="title" id="title-4">4. How to query and browse result of mission submitted</h1>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-4-1">4.1 Query result by mission ID</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">Step1: Click the “magnifying glass” icon in navigation bar to go to
                                query page.</li>

                            <li class="step-item">Step2: Type in your email address left in submission and the Task id
                                shown in reminder email.
                            </li>
                            <li class="step-item">Step3: Click “Search” button for query and display.
                            </li>

                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F4_1_1.jpg" alt="">
                            </div>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F4_1_2.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                    <div class="chapter-block">
                        <h2 class="subtitle" id="title-4-2">4.2 Browse result by email</h2>
                        <ul class="step-wrapper">
                            <li class="step-item">The links offered in reminder email would bring you to the
                                corresponding result page directly.
                            </li>
                            <div class="img-wrapper">
                                <img class="tutorial-img" src="../assets/tutorial/F4_2.jpg" alt="">
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        goAnchor(id) {
            document.getElementById(id).scrollIntoView();
        }
    }
};
</script>

<style>
main {
    /* display: flex; */
    /* padding-left: 500px; */
    margin: 0 200px;
}

a:active {
    color: var(--color2-light)
}

.content-wrapper {
    display: flex;
    justify-content: center;
}

.img-wrapper {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.img-wrapper img {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}

.tutorial-title {
    text-align: center;
    margin: 20px 0;
    color: white;
}


.content-title {
    margin: 10px 0;
    text-align: center;
}

.chapter {
    color: white;
}

.chapter .title {
    margin: 10px 0;
}

.chapter .subtitle {
    margin: 20px;
}

.step-wrapper {
    margin: 0 20px;
}

.step-item {
    margin: 10px;
    font-size: 18px;
    line-height: 25px;
}

.tutorial-img {
    max-width: 1000px;
}

#tutorial {
    /* padding: 0 180px; */
    padding-top: 50px;
    min-height: 80vh;
}

.content {
    width: fit-content;
    background-color: var(--color2-dark);
    padding: 20px 30px;
    height: fit-content;
    margin-bottom: 50px;
}

.content-chapter .content-title {
    font-size: 20px;
}

.content-chapter .content-subtitle {
    font-size: 18px;
    margin-left: 30px;
}

.content-subtitle a {
    margin: 12px 0;
    display: block;
}
</style>
